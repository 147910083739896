import React, { useEffect, useState, CSSProperties, useRef, useMemo, useCallback } from "react";
import { DropdownProps, Modal } from "semantic-ui-react";
import moment from 'moment';
import { DateLocalizer, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import SearchBox from "react-lib/appcon/common/SearchBox";
import CardDentistScheduleUX from "./CardDentistScheduleUX";
import ModSchedulingDetailUX from "./ModSchedulingDetailUX";
import ModEventDetail from "./ModEventDetail";
import CardSelectTemplate from "./CardSelectTemplate";
import { timeSlotList } from "react-lib/apps/Scheduling/common/Utils";
import { DsbStatus } from "react-lib/apps/Scheduling/common/Model";
import { blockListForDentistSchedule } from "react-lib/apps/Scheduling/common/BlockList";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModConfirmAuthen from "react-lib/apps/common/ModConfirmAuthen";
import { formatADtoBEString } from "react-lib/utils/dateUtils";
import CONFIG from "config/config";
import { ADMIN_NAME_LIST } from "../USER/CardSettingDivision";
import { useIntl } from "react-intl";

console.log("initial moment locale en week dow 1")
moment.updateLocale('en', { week: { dow: 1 } });

const localizer = momentLocalizer(moment);
const CARD_DENTIST_SCHEDULE ="DentistSchedule"

const NO_DF_OPTIONS = {key: "null", value: "null", text: "ไม่รับค่าตอบแทนแพทย์"}


const formats = {
  monthHeaderFormat: (date: Date, culture?: string, localize?: DateLocalizer) => {
    let defaultMonth = localize?.format(date, "MMMM YYYY", culture)
    let BEYear = Number(localize?.format(date, "YYYY")) + 543
    let thaiMonth = localize?.format(date, "MMMM", "th-TH")
    return `${defaultMonth}  (${thaiMonth} ${BEYear})`
  }
};

const CardDentistSchedule = (props: any) => {
  const intl = useIntl();
  // console.log("DentistSchedule props.chairList", props.chairList)
  // console.log("DentistSchedule props.blockList", props.blockList)
  const chairExistProvider = Object.fromEntries((props.chairExistDSBList || []).concat({provider: 0, zone_id: null})
  .map((item: any) => [item.provider, item.zone_id ? ` (${item.zone_name})`: ""]));

  const chairExists = (props.chairExistDSBList || []).map((item: any) => item.chair_id)
  const chairOptions = [{ key: 0, value: 0, text: "ไม่ระบุ" }].concat(
    (props.chairList || [])
      // .filter((chair: any) =>
      //   chair.division === props.selectedDivision?.id)
      .filter((chair: any) => chairExists.includes(chair.id))
      .map((chair: any) => (
        { key: chair.provider || chair.name, value: chair.provider, text: chair.name })
      )
  );

  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const [selectedExaminationType, setSelectedExaminationType] = useState<string>("");
  const [showFormModal, setShowFormModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [timeOptions, setTimeOptions] = useState<any[]>([]);

  // Default
  const [defaultChair, setDefaultChair] = useState<any>(null);
  const [defaultSlotLength, setDefaultSlotLength] = useState<any>(5);

  // Individual block edit
  const [event, setEvent] = useState<any>(null);
  const [chair, setChair] = useState<any>(null);
  const [inactive, setInactive] = useState(false);
  const [doctorStartSerial, setDoctorStartSerial] = useState<string | number>("");
  const [doctorEndSerial, setDoctorEndSerial] = useState<string | number>("");
  const [slotLength, setSlotLength] = useState("5");
  const [examinationIndividual, setExaminationIndividual] = useState<number | null>(null);


  const [calendarYear, setCalendarYear] = useState<string[]>([moment().format("YYYY")]);

  const handleSelectTimeout = useRef(0);

  // ModConfirm
  const [openModMessage, setOpenModMessage] = useState(false);
  const [currentValue, setCurrentValue] = useState("");

  // only old patient
  const [exceptNewPatientAll, setExceptNewPatientAll] = useState<boolean>(false);
  const [exceptNewPatient, setExceptNewPatient] = useState<boolean>(false);

  // add Option
  const [examinationTypeOptions, setExaminationTypeOptions] = useState([NO_DF_OPTIONS])
  // Feature 68931
  const [readOnly, setReadOnly] = useState(false); // กรณี readOnly
  const [disabledProvider, setDisabledProvider] = useState(false); // หมอ
  const [disabledExaminationType, setDisabledExaminationType] = useState(false) // ทุก Roles

  useEffect(() => {
    props.onEvent({ message: "GetChairList", params: {} });
    props.onEvent({
      message: "GetChairWithDivisionServiceBlock",
      params: { division: props.selectedDivision?.id || "" },
    });
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "GetScheduleTemplate",
      },
    });

    props.onEvent({
      message: "GetMasterData",
      params: { masters: [["prenameTh", {}]] },
    });
  }, []);

  useEffect(() => {
    if (props.telephar){
      setSelectedProvider({provider_id: CONFIG.TELEPHAR_PROVIDER})
    } else {
      setSelectedProvider(null)
    }
  }, [props.telephar]);


  // Issue 70646
  // Clinic อื่นๆ
  // หมอลงตารางแพทย์ได้เฉพาะของตนเอง readonly ชื่อแพทย์
  // พยาบาล และ อื่นๆ ลงตารางให้แพทย์ทุกคน ที่มี division has user ของ แผนกที่พยาบาล เลือกทำงาน
  // readonly ประเภทการออกตรวจ เมื่อไม่ใช่ Clinic premium

  // Clinic premium
  // readonly ชื่อแพทย์ ลงตารางแพทย์ไม่ได้ กด ดูปฏิทินได้ กด พิมพ์ ได้
  // พยาบาล และ อื่นๆ ลงตารางให้แพทย์ทุกคน ที่มี division has user ของ แผนกที่พยาบาล เลือกทำงาน


  useEffect(() => {
    console.log("useEffect FilterSchedule divisionId/initial props.selectedDivision: ", props.selectedDivision)
    props.onEvent({
      message: "FilterSchedule",
      params: {
        divisionId: props.selectedDivision?.id,
        initial: selectedDivision === null
      },
    });
    props.onEvent({ message: "Holiday", params: { action: "refresh" }});

    console.log('CardDentistSchedule props.providerInfo: ', props.providerInfo);

    const providerId = props.providerInfo?.provider_id;
    console.log('CardDentistSchedule providerId: ', providerId);
    const roleTypes: string[] = props.django?.user?.role_types || [];
    const isDoctor = !!roleTypes.includes("DOCTOR");
    const isNurse = !!roleTypes.includes("REGISTERED_NURSE");

    const isDoctorMenu = props.menuName === ADMIN_NAME_LIST.ลงตารางแพทย์ && isDoctor;

    setReadOnly(false);
    setDisabledProvider(false);
    setDisabledExaminationType(false);

    console.log('CardDentistSchedule isDoctorMenu: ', isDoctorMenu);
    if (isDoctorMenu) {
      setDisabledProvider(true);
    } else {
      setDisabledProvider(false);
    }

    if (providerId) {
      setSelectedProvider(props.providerInfo);
    } else {
      setSelectedProvider(null);
    }

    const isPremiumClinic = props.selectedDivision?.type_label === "คลินิกพรีเมี่ยม";
    const isOperatingRoom = props.selectedDivision?.type_label === "ห้องผ่าตัด";
    const isPremiumNProvider = isPremiumClinic && providerId;
    const canEditExaminationType = (isDoctor || isNurse) && isOperatingRoom;

    console.log('CardDentistSchedule isPremiumClinic: ', isPremiumClinic);

    if (isPremiumNProvider && isDoctorMenu) {
      console.log("CardDentistSchedule premium and doctor ");
      setReadOnly(true);
    }

    if (!isPremiumClinic && !canEditExaminationType) {
      setSelectedExaminationType("null");
      setDisabledExaminationType(true);
    }

    setSelectedDivision(props.selectedDivision);
  }, [props.selectedDivision?.id, selectedDivision]);

  useEffect(() => {
    console.log("useEffect FilterSchedule providerId")
    props.onEvent({
      message: "FilterSchedule",
      params: {
        providerId: selectedProvider?.provider_id,
      },
    });
  }, [selectedProvider]);

  useEffect(() => {
    if (Array.isArray(props.masterOptions?.examinationType) && props.masterOptions?.examinationType?.length > 0) {
      setExaminationTypeOptions([NO_DF_OPTIONS, ...props.masterOptions?.examinationType])
    }

  }, [props.masterOptions?.examinationType])

  const formatProviderToDisplay = useCallback(
    (item: any) => {
      const formatName = (data: Record<string, any>) => {
        const prenameThOptions: Record<string, any>[] = props.masterOptions?.prenameTh || [];

        const foundPrename = prenameThOptions.find((option) => option.value === data.pre_name);
        const prename = typeof data.pre_name === "string" ? data.pre_name : "";
        const code = data.code ? ` (${data.code})` : "";

        return `${foundPrename?.text || prename || ""} ${data.first_name} ${data.last_name}${code}`;
      };

      return readOnly || disabledProvider ? formatName(props.providerInfo || {}) : formatName(item);
    },
    [disabledProvider, props.masterOptions?.prenameTh, readOnly]
  );

  const blockList = useMemo(() => {

    let bl = blockListForDentistSchedule(props.blockList, props.selectedDivision, selectedProvider, props.chairList, props.providerBlockList,props.telephar);

    // console.log('selectedProvider: ', selectedProvider);
    if (selectedProvider === null) {
      // ไม่ได้ เลือกแพทย์
      // มี dsb เมื่อไม่เลือก แพทย์​

      bl = bl.map((item: any) => {
        // console.log('item: ', item);
        let doctorCount = item.providers?.filter((item: any) => (props.telephar?["TelePhar"]:["Doctor"]).includes(item.dsb.doctor_provider_type) && item?.dsb?.doctor_dsb_status !== 2)?.length
        // console.log('BlockList !! doctorCount: ', doctorCount);
        let doctorCountText = doctorCount > 0 ? ` (แพทย์ ${doctorCount} คน)` : "";
        return ({
          ...item,
          title : `${item.title}${doctorCountText}`
        })
      })

    } else {
      // เลือกแพทย์
      // ไม่มี dsb เมื่อเลือก แพทย์

      bl = bl.map((item: any) => {
        let patientCount = selectedProvider && item.providers?.find((item: any) => item.doctor_provider_id === selectedProvider?.provider_id)?.appointments?.length
        let patientCountText = patientCount > 0 ? ` (คนไข้ ${patientCount} คน) `: ""

        return ({
          ...item,
          title : `${item.title}${patientCountText}`
        })
      })
    }
    // console.log(selectedProvider, 'bl: ', bl);
    return bl
  }, [props.blockList, props.selectedDivision, selectedProvider, props.chairList, props.providerBlockList]);

  const blockListAndHoliday = useMemo(() => {
    const holiday = (props.holiday?.items || []).flatMap((item: any) => {
      const [day, month, year] = item.date[0].split("/");
      const date = `${year - 543}-${month}-${day}`;
      return { start: new Date(`${date} 00:00`), end: new Date(`${date} 23:59`), title: item.detail, holiday: true };
      // return calendarYear.map((year)=>{
      // const date = `${year}-${month}-${day}`;
      //   return { start: new Date(`${date} 00:00`), end: new Date(`${date} 23:59`), title: item.detail, holiday: true };
      // })
    });
    console.log("blocklist holiday: ", holiday)
    return [...holiday, ...blockList];

  }, [calendarYear, blockList, props.holiday?.items]);

  const allowDeleteSchedule = useMemo(() => {
    const provider = event?.providers?.[0] || {};

    if (provider.doctor_dsb_id) {
      return moment(provider.date).isSameOrAfter(moment().startOf("days"));
    }

    return false;
  }, [event]);

  const updateTimeOptions = (start_serial: number, end_serial: number, gap: number) => {
    setTimeOptions(timeSlotList(start_serial, end_serial, gap, false));
  };

  const handleSelectEvent = (
    event: any, e: React.SyntheticEvent<HTMLElement, globalThis.Event>
  ) => {

    console.log('blockListAndHoliday: ', blockListAndHoliday);

    const singleClick = () => {
      console.log("single click ", props.blockList)
        // Beyond 200 ms, consider a normal click
        handleSelectTimeout.current = 0;
        setEvent(event);
        if (selectedProvider) {
          // console.log("handleSelectEvent", event)

          if(event?.providers?.[0]?.chair_provider_id){
            setChair(event?.providers?.[0]?.chair_provider_id || defaultChair);
            props.onEvent({
              message: "GetChairWithDivisionServiceBlock",
              params: {
                start_serial: event.start_serial,
                end_serial: event.end_serial,
                only_chair: true,
                division: props.selectedDivision?.id,
                exclude: [event?.providers?.[0]?.chair_provider_id]
              }
            })
          } else {
            props.onEvent({
              message: "GetChairWithDivisionServiceBlock",
              params: {
                start_serial: event.start_serial,
                end_serial: event.end_serial,
                only_chair: true,
                division: props.selectedDivision?.id
              }
            })
          }
          console.log('event?.providers?.[0]: ', event?.providers?.[0]);
          setInactive(event?.providers?.[0]?.doctor_dsb_status === DsbStatus.INACTIVE);
          setExceptNewPatient(event?.providers?.[0]?.only_old_patient || false)
          setDoctorStartSerial(event?.providers?.[0]?.doctor_start_serial || event?.start_serial);
          setDoctorEndSerial(event?.providers?.[0]?.doctor_end_serial || event?.end_serial);
          setSlotLength(event?.providers?.[0]?.doctor_dsb_slot_length.toString() || defaultSlotLength);
          setExaminationIndividual(event?.providers?.[0]?.doctor_dsb_examination_type || "null");
          setShowFormModal(true);
        } else {
          // For division, not specific provider
          setShowListModal(true);
          setExaminationIndividual(null)
        }
    }

    if (event?.start_serial && event?.end_serial) {
      updateTimeOptions(event.start_serial, event.end_serial, 6);
    }

    // Save current target in case we need to use it
    // console.log("handleSelectEvent event: ", event);
    if (event?.holiday || readOnly) {
      singleClick()
      return;
    }

    // #55768 สีเหลืองกดไม่ได้
    if (event?.division_id !== props.selectedDivision?.id) {
      return
    }


    // const currentTarget = e.currentTarget;
    if (!handleSelectTimeout.current) {
      handleSelectTimeout.current = setTimeout(() => {
        singleClick()
      }, 200) as any;
    } else {
      // Before 200 ms, consider a 'double click
      console.log("double click ")
      clearTimeout(handleSelectTimeout.current);
      handleSelectTimeout.current = 0;
      if (selectedProvider) {
        props.onEvent({
          message: "AddBlock",
          params: {
            event: event,
            selectedProvider: selectedProvider,
            chair: defaultChair,
            inactive: typeof event?.providers?.[0]?.doctor_dsb_status !== "undefined" && event?.providers?.[0]?.doctor_dsb_status !== DsbStatus.INACTIVE,
            slot_length: defaultSlotLength,
            examinationType: selectedExaminationType,
          },
        });
      } else {
        // For division, not specific provider
      }
    }
  };

  const handleConfirmAddBlock = () => {
    props.onEvent({
      message: "AddBlock",
      params: props.openModConfirmAddBlock?.params,
    });
  };

  const handleFinish = (params = {}) => {
    const zone = (props.chairExistDSBList || []).find((item: any) => item.provider === chair)?.zone;

    props.onEvent({
      message: "AddBlock",
      params: {
        event,
        selectedProvider,
        chair,
        zone,
        inactive,
        exceptNewPatient,
        doctorStartSerial,
        doctorEndSerial,
        slotLength,
        examinationType: examinationIndividual,
        ...props.openModConfirmAddBlock?.params,
        ...params,
        callback: () => {
          props.onEvent({
            message: "GetChairWithDivisionServiceBlock",
            params: { division: props.selectedDivision?.id || "" },
          });
          setShowFormModal(false);
        },
      },
    });
  };

  const handleChange = (e: any, { value }: any) => setCurrentValue(value);

  const handleCancelButton = () => {
    setOpenModMessage(false);
    setCurrentValue("");
    props.setProp("userTokenize", null);
  };

  const handleConfirmedButton = () => {
    handleFinish({ note: currentValue || "", isDelete: true })
    handleCancelButton()
  };

  // const chairProviderIdList = event?.chairs?.length > 0 ?
  // (event?.chairs || []).map((chair: any) => chair?.chair_provider_id)
  // : (chairOptions || []).map((chair: any) => chair.value);
  const chairProviderIdList = (chairOptions || []).map((chair: any) => chair.value);

  // console.log("CardDentistSchedule props: ", props);
  // console.log("CardDentistSchedule blockList: ", blockList);
  // console.log("event?.providers?.[0]?.appointmentList?.length,", event?.providers?.[0]?.appointments?.length)
  // console.log("event?.providers?.[0]?.appointmentList?.length,", event)
  return (
    <div style={{ height: "90vh", padding: "5px" }}>
      <CardDentistScheduleUX
        readOnly={readOnly}
        disabledExaminationType={disabledExaminationType}
        isShowChair={CONFIG.SHOW_DOCTOR_CHAIR}
        // Examination Type
        examinationTypeOptions={examinationTypeOptions}
        onChangeExaminationType={(
          e: React.SyntheticEvent<HTMLElement, Event>,
          { value }: DropdownProps
        ) => {
          setSelectedExaminationType(value as string);
        }}
        examinationType={selectedExaminationType}
        // Select provider
        telephar={props.telephar}
        selectedProvider={selectedProvider}
        providerSelector={
          <SearchBox
            onEvent={props.onEvent}
            disabled={readOnly || disabledProvider}
            division={props.selectedDivision?.id}
            dropdownStyle={{ width: "19.5vw" }}
            label={intl.formatMessage({ id: "แพทย์" })}
            placeholder={intl.formatMessage({ id: "ค้นหาชื่อแพทย์" })}
            searchedItemList={props.searchedItemList}
            selectedItem={selectedProvider}
            style={{ width: "100%" }}
            toDisplay={formatProviderToDisplay}
            type="Provider"
            fluid
            vertical
            setSelectedItem={setSelectedProvider}
          />
        }
        // Default
        defaultSlotLength={defaultSlotLength}
        changeDefaultSlotLength={(e: any, v: any) => {
          let num = Math.trunc(v.value);
          if (!isNaN(num)) {
            setDefaultSlotLength(num);
          }
        }}
        defaultChair={defaultChair}
        chairOptions={chairOptions}
        changeDefaultChair={(e: any, v: any) => setDefaultChair(v.value)}
        // chair={chair}
        // changeChair={(e: any, v: any) => setChair(v.value)}

        cardSelectTemplate={
          <CardSelectTemplate
            readonly={readOnly}
            clearDentisSchduleForm={() => {
              setSelectedExaminationType("");
              setSelectedProvider(null);
              setSlotLength("5");
              setDefaultChair(null);
              setDefaultSlotLength(5);
            }}
            scheduleTemplates={props.scheduleTemplates}
            disableCreate={selectedProvider === null || readOnly}
            disableClearData={readOnly || disabledProvider}
            createDSBFromTemplateItems={(
              templateItems: any[],
              templateItemIndexList: number[],
              startDateTime: string,
              endDateTime: string,
              card: string,
              buttonLoadKey: string
            ) => {
              // console.log("createDSBFromTemplateItems templateItems: ", templateItems);
              // console.log("createDSBFromTemplateItems templateItemIndexList: ", templateItemIndexList);
              // console.log("createDSBFromTemplateItems startDateTime: ", startDateTime);
              // console.log("createDSBFromTemplateItems endDateTime: ", endDateTime);

              let items = templateItems.filter((item: any, index: number) =>
                templateItemIndexList.includes(index)
              );
              items = items.map((item: any) => ({
                ...item,
                chair: defaultChair,
                slot_length: defaultSlotLength,
              }));

              props.onEvent({
                message: "SetScheduling",
                params: {
                  action: "CreateDSBFromTemplateItems",
                  items: items,
                  startDateTime: startDateTime,
                  endDateTime: endDateTime,
                  exceptNewPatientAll: exceptNewPatientAll,
                  providers: [selectedProvider?.provider_id],
                  card: card,
                  buttonLoadKey: buttonLoadKey,
                  examinationType: selectedExaminationType,
                },
              });
            }}
            chairOptions={chairOptions}
            defaultChair={defaultChair}
            defaultSlotLength={defaultSlotLength}
            selectedDivision={selectedDivision}
            onSelectTemplateDate={(start_date: any, end_date: any) => {
              props.onEvent({
                message: "GetChairWithDivisionServiceBlock",
                params: { start_date, end_date },
              });
            }}
            buttonLoadCheck={props.buttonLoadCheck}
            setProp={props.setProp}
            card={CARD_DENTIST_SCHEDULE}
            languageUX={props.languageUX}
          />
        }
        disablePrint={!selectedProvider}
        // Print
        printSummary={(e: any) => {
          props.onEvent({
            message: "PrintScheduling",
            params: {
              form: "FormDentistSchedule",
              data: {
                divisionDict: props.allDivisionDict,
                provider_name: `${selectedProvider?.first_name || ""} ${
                  selectedProvider?.last_name || ""
                }`,
                orders: blockList,
              },
            },
          });
        }}
        // flag accept new patient
        exceptNewPatientAll={exceptNewPatientAll}
        changeExceptNewPatientAll={() => setExceptNewPatientAll(!exceptNewPatientAll)}
        // Block
        minTime={new Date(1972, 0, 1, 8, 0, 0)}
        maxTime={new Date(1972, 0, 1, 20, 0, 0)}
        localizer={localizer}
        blockList={blockListAndHoliday}
        formats={formats}
        eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
          // console.log("------------------------------------------------------------------------------------------------------------------")
          // console.log("CardDentistSchedule blockList: ", blockList);
          // console.log("CardDentistSchedule event", event);
          // console.log("CardDentistSchedule event?.providers?.[0]?.doctor_dsb_status", event?.providers?.[0]?.doctor_dsb_status)
          // console.log("CardDentistSchedule event?.providers?.[0]?.doctor_dsb_status", event?.providers?.[0]?.dsb?.doctor_dsb_status)

          // มี dsb เมื่อไม่เลือก แพทย์​
          // ไม่มี dsb เมื่อเลือก แพทย์

          // ดังนั้นต้อง แยก Case ก่อน

          // สีชมพู คือ provider?.length = 0
          // ไม่เลือกหมอ แล้ว มีหมอ แต่ ไม่ออกตรวจ
          // เลือกหมอ แล้วในนั้น ไม่มีใครออก รวม เฉพาะหมอที่ออกตรวจ เท่ากับ 0

          // สีเทา (เฉพาะเลือกแพทย์ )
          // ตัวเองออกตรวจ แต่งดออกตรวจ

          // สีเขียว (เฉพาะเลือกแพทย์ )
          // ตัวเองออกตรวจแบบ มีเก้าอี้

          // สีส้ม (เฉพาะเลือกแพทย์)
          // ตัวเองออกตรวจที่ division อื่น

          // สีน้ำเงิน
          // เลือกแพทย์
          //// ตัวเองออกตรวจ ที่ตัวเองไม่งดตรวจ (ออกตรวจจริง)
          // ไม่เลือกแพทย์
          //// มีคนใดคนหนึ่งออกตรวจจริง ()

          // console.log("---------------*********----")
          // Issue 55924

          if (selectedProvider === null) {
            // ไม่เลือกแพทย์ มี dsb
            let doctorCount = event.providers?.filter(
              (item: any) =>
                (props.telephar ? ["TelePhar"] : ["Doctor"]).includes(
                  item.dsb.doctor_provider_type
                ) && item?.dsb?.doctor_dsb_status !== 2
            )?.length;
            return {
              style: event?.holiday
                ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" }
                : doctorCount === 0
                ? { backgroundColor: "pink", color: "black" }
                : ({} as CSSProperties),
            };
          } else {
            // เลือกแพทย์ ไม่มี dsb
            // console.log("props.selectedDivision?.id", props.selectedDivision?.id)
            // console.log("event?.division_id", event?.division_id)
            return {
              style: event?.holiday
                ? { backgroundColor: "#FFE696", color: "black", textAlign: "center" }
                : event?.division_id !== props.selectedDivision?.id
                ? { backgroundColor: "orange", color: "black" }
                : event?.providers?.[0]?.doctor_dsb_status === 2
                ? { backgroundColor: "#cccccc", color: "black" }
                : event?.providers?.[0]?.chairName && event?.providers?.[0]?.chairName !== null
                ? { backgroundColor: "green", color: "white" }
                : event?.providers?.length === 0
                ? { backgroundColor: "pink", color: "black" }
                : ({} as CSSProperties),
            };
          }
        }}
        onRangeChange={(range: any, view: any) => {
          const year = Array.isArray(range)
            ? [range[0], range.slice(-1)[0]]
            : [range.start, range.end];
          const uniqueYear = Array.from(
            new Set([moment(year[0]).format("YYYY"), moment(year[1]).format("YYYY")])
          );
          if (uniqueYear.toString() !== calendarYear.toString()) {
            setCalendarYear(uniqueYear);
          }
        }}
        onSelectEvent={handleSelectEvent}
        languageUX={props.languageUX}
      />

      {/* Open when selectedProvider, show details for doing scheduling */}
      <Modal
        open={showFormModal}
        closeOnDimmerClick={true}
        onClose={() => {
          props.onEvent({
            message: "GetChairWithDivisionServiceBlock",
            params: { division: props.selectedDivision?.id || "" },
          });
          setShowFormModal(false);
        }}
      >
        <ModSchedulingDetailUX
          disabled={readOnly}
          blockDetail={event?.block_datetime}
          disabledExaminationType={disabledExaminationType}
          // Start / End time
          dentistStartSerial={doctorStartSerial}
          startTimeOptions={timeOptions}
          changeStartSerial={(e: any, v: any) => setDoctorStartSerial(v.value)}
          dentistEndSerial={doctorEndSerial}
          endTimeOptions={timeOptions}
          changeEndSerial={(e: any, v: any) => setDoctorEndSerial(v.value)}
          // Chair
          chair={chair}
          chairOptions={chairOptions
            .filter(
              (option: any) => option.value === 0 || chairProviderIdList.includes(option.value)
            )
            .map((option: any) => {
              return { ...option, text: option.text + chairExistProvider?.[option.value] || "" };
            })}
          changeChair={(e: any, v: any) => setChair(v.value)}
          // Capacity
          slotLength={slotLength}
          changeSlotLength={(e: any) => setSlotLength(e.target.value)}
          // Inactive
          inactive={inactive}
          changeInactive={() => setInactive(!inactive)}
          exceptNewPatient={exceptNewPatient}
          changeExceptNewPatient={() => setExceptNewPatient(!exceptNewPatient)}
          allowDeleteSchedule={allowDeleteSchedule}
          onDeleteSchedule={() => setOpenModMessage(true)}
          // Finish button
          finish={(e: any) => {
            if (selectedProvider) {
              handleFinish();
            } else {
              // For division, not specific provider
              props.onEvent({
                message: "GetChairWithDivisionServiceBlock",
                params: { division: props.selectedDivision?.id || "" },
              });
            }
          }}
          disabledDeleteSchedule={event?.providers?.[0]?.appointments?.length > 0}
          // Add ExaminationType
          examinationTypeOptions={examinationTypeOptions}
          onChangeExaminationType={(e, { value }) => {
            setExaminationIndividual(value);
          }}
          examinationType={examinationIndividual}
          languageUX={props.languageUX}
        />
      </Modal>

      {/* Open when no selectedProvider, show details of scheduled blocks */}
      <Modal
        size="fullscreen"
        open={showListModal}
        closeOnDimmerClick={true}
        onClose={(e: any) => setShowListModal(false)}
      >
        <ModEventDetail
          onEvent={(params) => {
            if (params.message === "RowClick") {
              console.log(params.params);
            }
          }}
          chairList={props.chairList}
          divisionDict={props.allDivisionDict}
          isShowChair={CONFIG.SHOW_DOCTOR_CHAIR}
          providers={event?.providers}
          selectedBlock={props.selectedBlock}
          title={`${formatADtoBEString(event?.date)} ${event?.title}`}
        />
      </Modal>

      <ModConfirm
        closeIcon={true}
        titleColor={"red"}
        openModal={!!props.openModConfirmAddBlock}
        titleName={intl.formatMessage({ id: "ยืนยันงดออกตรวจ" })}
        content={
          <div
            style={{ textAlign: "center", lineHeight: 2.25, margin: "-10px", fontWeight: "bold" }}
          >
            <div>วันที่ {props.openModConfirmAddBlock?.datetime || ""}</div>
            <div>
              มีผู้ป่วยนัดหมาย {props.openModConfirmAddBlock?.provider_name || ""} จำนวน{" "}
              {props.openModConfirmAddBlock?.number || ""} คน
            </div>
            <div>{intl.formatMessage({ id: "จะต้องยืนยันวันเวลานัดหมายใหม่อีกครั้ง" })}</div>
          </div>
        }
        onDeny={() => {
          props.setProp("openModConfirmAddBlock", null);
          props.setProp("dsbAppointments", []);
        }}
        onApprove={handleConfirmAddBlock}
        onCloseWithDimmerClick={() => {
          props.setProp("openModConfirmAddBlock", null);
          props.setProp("dsbAppointments", []);
        }}
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        approveButtonColor={"green"}
        denyButtonText={intl.formatMessage({ id: "ไม่" })}
        denyButtonColor={"red"}
      />

      <ModConfirmAuthen
        {...{
          title: "ยืนยันลบตารางออกตรวจ",
          questionDetail: "ระบุเหตุผลการลบตารางออกตรวจ",
          titleColor: "red",
          userEditQuestion: "ระบุชื่อผู้บันทึกข้อมูล",
          buttonLeftLabel: "ใช่",
          buttonRightLabel: "ไม่",
          buttonLeftColor: "green",
          buttonRightColor: "red",
          size: "small",
          // disableButtonLeft: !props.userTokenize?.employeeName || !currentValue,
          isUserEditQuesion: true,
          isErrMsg: true,
        }}
        onEvent={props.onEvent}
        setProp={props.setProp}
        userTokenize={props.userTokenize}
        onButtonLeftClick={handleConfirmedButton}
        onButtonRightClick={handleCancelButton}
        open={openModMessage}
        inputType="textarea"
        onClose={handleCancelButton}
        isButtonBasic={true}
        handleChange={handleChange}
        currentValue={currentValue as any}
      />
    </div>
  );
}

export default CardDentistSchedule;